<template>
  <div class="wrapper">
    <ul>
      <li v-for="(item, index) in MoMoLinkList" :key="index">
        <a :href="item.link">{{ index + 1 }}. {{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'MoMoProtocol',
    components: {},
    props: {},
    data() {
      return {
        MoMoLinkList: [
          {
            name: '《陌陌用户协议》',
            link:
              'https://g.immomo.com/fep/momo/m-fep-projects/exlogin/userProtocol.html?_bid=1002286&_ui_mode=0&_ui_bg=ffffff&_wk=1&_resize=1',
          },
          {
            name: '《陌陌隐私权协议》',
            link:
              'https://g.immomo.com/fep/momo/m-fep-projects/exlogin/privateProtocol.html?_bid=1002286&_ui_mode=0&_ui_bg=ffffff&_wk=1&_resize=1',
          },
        ],
      };
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style lang="less" scoped>
  ul {
    li {
      margin: 0.3rem;
      a {
        font-size: 0.26rem;
        color: #007aff;
        text-decoration: none;
      }
    }
  }
</style>
